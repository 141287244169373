import React, {Component} from 'react'

import IcoFullstak from '@fullstakk/fms-frontend-asset/dist/img/fullstakk-logo-white-16.png'

/**
 * Account login form.
 */
export default class AccountLogIn extends Component {
    /**
     * Renders the component.
     */
    render() {
        // onSubmit={this.props.onSubmit}
        return (
            <React.Fragment>
                <form method='get' action='/app/auth' className='form-auth'>
                    <p className='text-center'>
                        <button type='submit' data-type='login-google' className='button' onClick={this.onClick}>
                            <i className='fab fa-google'></i> Sign in with Google
                        </button>
                    </p>
                </form>
                <form method='get' onSubmit={this.props.onSubmit} className='form-auth'>
                    <p className='text-center'>
                        <span className='text-separator'>Or</span>
                    </p>
                    <p className='text-center'>
                        <input type='text' name='email' id='email' placeholder='E-mail' required='required'/>
                    </p>
                    <p className='text-center'>
                        <input type='password' name='password' placeholder='Password' required='required'/>
                    </p>
                    <p className='text-center'>
                        <button type='submit' data-type='login-password' className='button' onClick={this.onClick}>
                            <img src={IcoFullstak} width={16} height={13} alt='Fullstakk logo' /> Sign in with e-mail
                        </button>
                    </p>
                    <p className='text-center'>
                        <a href='/account-creation'>Create an account</a>
                    </p>
                </form>
            </React.Fragment>
        )
    }

    /**
     * Handles click on one submit entry.
     *
     * @param Event event Click event that has occurred
     */
    onClick(event) {
        //event.preventDefault()
        document.forms[0].id = event.target.dataset.type
        document.forms[1].id = event.target.dataset.type
    }
}