import React from 'react'

import AccountLogIn from '../components/Form/AccountLogIn'

import Service from '../../lib/utility/Service'

/**
 * Encapsulates the login screen of the frontend.
 */
export default class ScreenLogin extends React.Component {
    /**
     * Initializes the screen.
     *
     * @param Object props Properties of the screen
     */
    constructor(props) {
        super(props)
        this.onFormSubmit = this.onFormSubmit.bind(this)
    }

    /**
     * Renders the screen.
     *
     * @returns {JSX.Element} Rendered screen
     */
    render() {
        return (
            <AccountLogIn onSubmit={this.onFormSubmit} />
        )
    }

    /**
     * Handles application user's authorization.
     *
     * @param Event event Submission event that has occurred
     */
    onFormSubmit(event) {
        event.preventDefault()
        const Auth = Service.instance('Auth')

        switch(event.target.id) {
            case 'login-password':
                Auth.signIn()
                Auth.signInPassword(event)
                break
            case 'login-google':
                Auth.signIn()
                Auth.signInGoogle(event)
                break
            default:
                break
        }

        return true
    }
}
