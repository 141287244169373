import React from 'react'

import Layout from '@fullstakk/fms-frontend-theme'

import Seo from '../lib/components/Application/Seo'
import Service from '../lib/utility/Service'

import Screen from '../app/screen/Login'

import '../app/assets/css/layout/fullscreen.scss'

/**
 * Encapsulates the homepage of the frontend.
 */
export default class Index extends React.Component {
    /**
     * Renders the page.
     *
     * @returns {JSX.Element} Rendered page
     */
    render() {
        const App = Service.instance('App')
        if(App.isBrowser()) {
            App.resetStorage()
        }
        /*const Auth = Service.instance('Auth')

        if (Auth.isLoggedIn()) {
            App.authRedirect()
            return null
        }*/

        return (
            <Layout
                theme='FullScreen'
                title={App.getName()}
                subTitle={(<span>By <a href="https://www.fullstakk.no/" rel="noopener noreferrer" target="_blank">Fullstakk Marketing</a></span>)}
            >
                <Seo/>
                <Screen/>
            </Layout>
        )
    }
}
